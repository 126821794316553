exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-animaux-js": () => import("./../../../src/pages/animaux.js" /* webpackChunkName: "component---src-pages-animaux-js" */),
  "component---src-pages-birds-js": () => import("./../../../src/pages/birds.js" /* webpackChunkName: "component---src-pages-birds-js" */),
  "component---src-pages-blog-details-js": () => import("./../../../src/pages/blog-details.js" /* webpackChunkName: "component---src-pages-blog-details-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-booking-js": () => import("./../../../src/pages/booking.js" /* webpackChunkName: "component---src-pages-booking-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-home-three-js": () => import("./../../../src/pages/home-three.js" /* webpackChunkName: "component---src-pages-home-three-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-parc-js": () => import("./../../../src/pages/parc.js" /* webpackChunkName: "component---src-pages-parc-js" */),
  "component---src-pages-reptiles-js": () => import("./../../../src/pages/reptiles.js" /* webpackChunkName: "component---src-pages-reptiles-js" */),
  "component---src-pages-rooms-js": () => import("./../../../src/pages/rooms.js" /* webpackChunkName: "component---src-pages-rooms-js" */),
  "component---src-templates-animal-post-js": () => import("./../../../src/templates/animal-post.js" /* webpackChunkName: "component---src-templates-animal-post-js" */),
  "component---src-templates-bird-post-js": () => import("./../../../src/templates/bird-post.js" /* webpackChunkName: "component---src-templates-bird-post-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-reptile-post-js": () => import("./../../../src/templates/reptile-post.js" /* webpackChunkName: "component---src-templates-reptile-post-js" */)
}

